import {register, SwiperSlide} from 'swiper/element/bundle';
import {AfterViewInit, Component, ContentChildren, Input, QueryList, Renderer2, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SwiperComponent implements AfterViewInit {
    @Input()
    swiperId = '';
    @Input()
    paginationType = 'fraction';

    @ContentChildren('swiper-slide')
    slides: QueryList<SwiperSlide> | undefined;

    constructor(private renderer: Renderer2) {
    }

    ngAfterViewInit(): void {
        register();

        const params = {
            injectStyles: [
                '.swiper-pagination-bullet-active {width: 6px !important;height: 6px !important;background: #000000 !important;border: 1px solid #000000;opacity: 1 !important;}',
                '.swiper-button-next, .swiper-button-prev {color: #999999 !important;}',
                '@media screen and (max-width: 599px) {.swiper-button-next, .swiper-button-prev {color: #f6f7f7 !important;opacity: .1;}}'
            ],
            autoplay: {
                delay: 15000
            },
            threshold: 30,
            grabCursor: true,
            pagination: {
                type: this.paginationType,
                clickable: true,
                hideOnClick: true,
            }
        };

        if (this.swiperId) {
            const container: any = document.getElementById(this.swiperId);
            if (container) {
                Object.assign(container, params);
                container.initialize();
            }
        } else {
            document.querySelectorAll('swiper-container')
                .forEach((container: any) => {
                    Object.assign(container, params);
                    container.initialize();
                });
        }
    }
}
