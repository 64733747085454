import {Component, Input} from '@angular/core';
import {User} from '../../../../domain/user/user';
import {Router} from '@angular/router';
import {DialogService} from '../../../../shared/dialog.service';

@Component({
  selector: 'app-expert-nav',
  templateUrl: './expert-nav.component.html',
  styleUrls: ['./expert-nav.component.scss']
})
export class ExpertNavComponent {
  @Input()
  user: User;

  constructor(private router: Router, private dialog: DialogService) {
  }

  clickQuestion() {
    this.dialog.openWithButtons('', '로핀 서비스 이용에 대한 문의를 하실 수 있습니다.\n법률상담은 무료상담 메뉴를 이용해주세요.', '문의하기', '취소')
      .then(confirm => {
        if (confirm.value) {
          this.router.navigateByUrl('/question');
        }
      });
  }
}
