<div fxLayout="row">
  <button mat-button routerLink="/consulting">사건사례</button>
  <button (click)="clickMenuItem('사건 관리', '/briefcases')" mat-button>사건 관리</button>
  <button (click)="clickMenuItem('캘린더', '/calendar')" mat-button>
    캘린더 <span *ngIf="countTodaySchedules > 0" class="badge m-l-2 f-10">{{countTodaySchedules}}</span>
  </button>

  <button [matMenuTriggerFor]="costMenu" mat-button>
    <span>상담 관리</span>
    <mat-icon class="c-gray">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #costMenu="matMenu">
    <button mat-menu-item routerLink="/case-selector">신규 작성</button>
    <button (click)="clickMenuItem('견적서 리스트', '/estimates')" mat-menu-item>상담 내역</button>
    <button (click)="clickMenuItem('임시 저장함', '/cost')" mat-menu-item fxHide>견적서 임시 저장함</button>
    <button (click)="clickMenuItem('보수표 관리', '/fee-tables')" mat-menu-item>보수표 관리</button>
    <button (click)="clickMenuItem('자동견적서 관리', '/auto-estimates')" fxHide mat-menu-item>자동견적 관리</button>
  </mat-menu>

  <button class="va-middle" (click)="clickMenuItem('상담 요청 사건', '/applications')" mat-button>
    상담 요청
    <span *ngIf="countApplications > 0" class="badge">{{countApplications}}</span>
  </button>

  <button [matMenuTriggerFor]="certificationMenu" mat-button>
    내용증명
    <mat-icon class="c-gray">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #certificationMenu="matMenu" xPosition="before">
    <button mat-menu-item routerLink="/certification">신규 작성</button>
    <button (click)="clickMenuItem('내용증명 작성 리스트', '/certification/list')" mat-menu-item>작성 내역</button>
    <button mat-menu-item routerLink="/certification/info">이용 방법</button>
  </mat-menu>
</div>
