<app-intro-expert-banner></app-intro-expert-banner>

<section class="wide-section bg-gray-fa" ngClass.gt-xs="p-t-20">
  <div ngClass.xs="p-h-10">
    <div class="mat-shadow-d p-10 p-t-20 m-b-30 bg-white">
      <div fxLayout="row wrap">

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/applications')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-estimate-client-gray.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">상담요청사건</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-myoffice.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">마이 로팀</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/briefcases')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-brief-white.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">사건 관리</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/calendar')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-calendar.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">캘린더</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/estimates')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-estimate-client-gray.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">상담 내역</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/case-selector')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-calculator-gray.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">견적 작성</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/fee-tables')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-estimate-set.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">보수표 관리</div>
          </div>
        </div>

        <div fxHide fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/auto-estimates')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-autoestimate.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">자동 견적</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/certification/list')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-certification-client-gray.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">내용증명</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/consulting/firm')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-counsel-client.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">사건사례</div>
          </div>
        </div>


        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/management/receivable')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-amount.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">미수금 관리</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/management')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-inout.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">입출금 현황</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/firm-statistics')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-statistics.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">운영 통계</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/firm-statistics/partner')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-distributions.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">파트너 배당</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/firm-experts')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-member-set.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">소속원 관리</div>
          </div>
        </div>

        <div fxFlex.gt-xs="20" fxFlex.xs="33">
          <div (click)="clickLink('/my-home/firm-info')" class="p-v-8 cursor hover" fxLayout="column"
               fxLayoutAlign="center center">
            <img ngClass.gt-xs="img-size-60" ngClass.xs="img-size-50"
                 src="assets/img/service/main-icon-expert-page.png">
            <div ngClass.gt-sm="mat-body-1 f-600" ngClass.xs="f-12 f-600">페이지 관리</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ngClass.gt-xs="m-t-50 m-b-20" ngClass.xs="m-v-30">
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.xs="20px" fxLayoutGap.gt-xs="30px">
      <div fxFlex.gt-xs="50">
        <div ngClass.xs="p-h-10">
          <div class="bg-white p-20 cursor" ngClass.xs="brd-15 p-v-35">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" routerLink="/contract">
              <div fxLayout="column" fxLayoutAlign="start start">
                <div class="mat-subheading-2 f-600 p-b-7 ff-sc-5" ngClass.xs="mat-subheading-1">전문가 사무소 등록</div>
                <div ngClass.gt-xs="mat-body-2 f-600" ngClass.xs="mat-body-1 f-600">
                  등록 즉시 전문가서비스를 무료로 이용하실 수 있습니다.
                </div>
              </div>
              <mat-icon class="mat-icon-size-45 c-primary">sentiment_satisfied_alt</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div fxFlex.gt-xs="50">
        <div ngClass.xs="p-h-10" routerLink="/apply-firm">
          <div class="bg-white p-20 cursor" ngClass.xs="brd-15 p-v-35">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px"
                 routerLink="/contract">
              <div fxLayout="column" fxLayoutAlign="start start">
                <div class="mat-subheading-2 f-600 p-b-7 ff-sc-5"  ngClass.xs="mat-subheading-1">소속원 등록</div>
                <div ngClass.gt-xs="mat-body-2 f-600" ngClass.xs="mat-body-1 f-600">
                  소속원 수의 제한없이 사무소에 등록할 수 있습니다.
                </div>
              </div>
              <mat-icon class="mat-icon-size-45 c-primary">people</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="bg-gray-checkteam cursor m-t-40">
    <a href="https://checkteam.co.kr" target="_blank" class="cursor">
      <div class="p-t-10">
        <div class="title-chip">체크팀 바로가기</div>
      </div>
      <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-around center"
           ngClass.xs="p-h-15 m-t-10">

        <div fxFlex.gt-xs="55" ngClass.gt-xs="p-l-20 p-b-20" ngClass.xs="p-b-20 text-center">
          <div>
            <div ngClass.gt-xs="mat-subheading-2 f-600 ff-sc-4 m-b-5"
                 ngClass.xs="mat-subheading-1 p-t-10 ff-sc-4 m-b-5">
              전세 &middot; 소유 &middot; 담보 부동산의
            </div>
            <div class="ff-sc-5 lh-1-7" ngClass.gt-xs="mat-title m-b-16" ngClass.xs="mat-subheading-2">
              위험한 등기정보를 누구나 확인할 수 있습니다!
            </div>
            <div ngClass.gt-xs="mat-body-2 ff-sc-4 m-b-5" ngClass.xs="mat-body-1 f-600 ff-sc-4 m-b-5">
              부동산등기변경이 확인되면 알림으로 안내됩니다.
            </div>
            <div class="p-t-20" routerLink="/info/my-house">
              <div class="p-b-10" fxLayout="column">
                <div fxLayout.xs="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="center center"
                     fxLayoutGap="5px">
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">임대보증금</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">부동산계약</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">가맹점</div>
                  <div class="gray-bg-chip-lg bg-accent-light" ngClass.xs="m-b-5">채권담보</div>
                </div>
              </div>
            </div>
          </div>

          <div fxHide.gt-xs fxLayout="column" fxLayoutAlign="center center" fxShow>
            <img alt="image" class="img-size-85p" routerLink="/info/my-house" src="assets/img/gif/main-notice-v03.gif">
          </div>

        </div>

        <div class="p-b-20 cursor" fxFlex.gt-xs="45" fxHide fxLayout="column" fxLayoutAlign="center center"
             fxShow.gt-xs>
          <img alt="image" class="img-size-60p"
               routerLink="/info/my-house" src="assets/img/gif/main-notice-v03.gif">
        </div>
      </div>
    </a>
  </div>



  <div ngClass.gt-xs="m-b-20 p-h-20" ngClass.xs="m-v-30">
    <div fxFlex.gt-xs="40" fxFlexOffset.gt-xs="60" fxFlex.xs="100">
      <div ngClass.xs="p-h-20">
        <app-intro-report></app-intro-report>
      </div>
    </div>
  </div>
</section>
