import {Component} from '@angular/core';
import {User} from '../../../domain/user/user';
import {NavigationEnd, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog.service';
import {GatewayService} from '../../../../../../gateway/src/lib/gateway.service';
import {UserService} from '../../../domain/user/user.service';

@Component({
  selector: 'app-expert-toolbar',
  templateUrl: './expert-toolbar.component.html',
  styleUrls: ['./expert-toolbar.component.scss']
})
export class ExpertToolbarComponent {
  countApplications = 0;
  countTodaySchedules = 0;

  constructor(private router: Router, private gateway: GatewayService, private userService: UserService, private dialog: DialogService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.userService.getCurrentUser().subscribe((user: User) => {
          if (user.expert) {
            this.getApplicationCounts();
            this.countTodaySchedules = user.countTodaySchedules;
          }
        });
      }
    });

    this.userService.change.subscribe((user: User) => {
      if (!user) {
        this.countApplications = 0;
        this.countTodaySchedules = 0;
      } else if (user.expert) {
        this.getApplicationCounts();
        this.countTodaySchedules = user.countTodaySchedules;
      }
    });

    this.getApplicationCounts();
  }

  clickMenuItem(title, url) {
    const user = this.userService.getSessionUser();

    if (!user) {
      this.dialog.openWithButtons(title,
        `로그인 후 ${title} 서비스를 이용할 수 있습니다.`,
        '로그인',
        '무료 회원가입')
        .then(confirm => {
          if (confirm.dismiss && confirm.dismiss === 'cancel') {
            this.router.navigateByUrl('/join');
          } else if (confirm.value) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: url}});
          }
        });
    } else if (!user.expert) {
      if (!user.switchable) {
        this.dialog.openWithButtons(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '소속원 신청',
          '전문가 등록')
          .then(confirm => {
            if (confirm.dismiss && confirm.dismiss === 'cancel') {
              this.router.navigateByUrl('/contract');
            } else if (confirm.value) {
              this.router.navigateByUrl('/apply-firm');
            }
          });
      } else {
        this.dialog.openWithConfirmButton(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '서비스 계정 변경')
          .then(confirm => {
            if (confirm.value) {
              this.router.navigateByUrl('/my-home/switch');
            }
          });
      }
    } else {
      this.router.navigateByUrl(url);
    }
  }

  private getApplicationCounts() {
    const user = this.userService.getSessionUser();
    if (user && user.expert) {
      this.gateway.get('/applications/count')
        .subscribe(result => {
          if (result.success) {
            this.countApplications = result.data;
          }
        });
    }
  }
}
