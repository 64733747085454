import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BottomToolbarService {
  public visible = new BehaviorSubject(true);

  constructor() {
  }
}
