import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../domain/user/user.service';
import {SpinnerService} from '../shared/spinner/spinner.service';
import {map} from 'rxjs/operators';
import {DialogService} from '../shared/dialog.service';
import {isPlatformBrowser} from '@angular/common';

@Injectable({providedIn: 'root'})
export class MemberGuard implements CanActivate {

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private userService: UserService,
              private spinnerService: SpinnerService,
              private dialog: DialogService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (isPlatformBrowser(this.platformId)) {
      this.spinnerService.change(false);

      return this.userService.checkSession().pipe(
        map(result => {
          if (!result) {
            this.dialog.openWithButtons('회원전용 서비스',
              `로그인 후 서비스를 이용할 수 있습니다.`,
              '로그인',
              '무료 회원가입')
              .then(confirm => {
                if (confirm.dismiss) {
                  if (confirm.dismiss === 'cancel') {
                    this.router.navigateByUrl('/join');
                  } else if (confirm.dismiss === 'backdrop') {
                    this.router.navigateByUrl('/');
                  }
                } else if (confirm.value) {
                  this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                }
              });
          }
          return result;
        })
      );
    } else {
      return true;
    }
  }
}
