import {Injectable} from '@angular/core';
import {GatewayService} from '../../../../gateway/src/lib/gateway.service';

export declare type EventType = 'ALL' | 'CLIENT' | 'EXPERT';

export interface EventQuery {
  admin?: boolean;
  type: EventType;
  page: number;
  size: number;
}

@Injectable()
export class EventFinderService {

  constructor(private gateway: GatewayService) {
  }

  getEvents(query: EventQuery) {
    return this.gateway.get('/events', query);
  }

  getEvent(id: number) {
    return this.gateway.get(`/events/${id}`);
  }
}
