import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {FooterComponent} from './footer/footer.component';
import {BottomToolbarComponent} from './bottom-toolbar/bottom-toolbar.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LeftComponent} from './side/left/left.component';
import {RightComponent} from './side/right/right.component';
import {ExpertToolbarComponent} from './toolbar/expert-toolbar/expert-toolbar.component';
import {ClientToolbarComponent} from './toolbar/client-toolbar/client-toolbar.component';
import {AnonymousNavComponent} from './side/left/anonymous-nav/anonymous-nav.component';
import {ClientNavComponent} from './side/left/client-nav/client-nav.component';
import {ExpertNavComponent} from './side/left/expert-nav/expert-nav.component';
import {FormsModule} from '@angular/forms';
import {SideComponent} from './side/side.component';
import {NotificationButtonComponent} from './toolbar/notification-button/notification-button.component';
import {SwitchServiceButtonComponent} from './toolbar/switch-service-button/switch-service-button.component';
import {DirectiveModule} from '../_directive/directive.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatChipsModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    DirectiveModule,
    ScrollingModule,
    MatExpansionModule,
    MatListModule,
    FormsModule,
    MatInputModule,
    MatTabsModule
  ],
  declarations: [
    ToolbarComponent,
    FooterComponent,
    BottomToolbarComponent,
    LeftComponent,
    RightComponent,
    ExpertToolbarComponent,
    ClientToolbarComponent,
    AnonymousNavComponent,
    ClientNavComponent,
    ExpertNavComponent,
    SideComponent,
    NotificationButtonComponent,
    SwitchServiceButtonComponent
  ],
    exports: [ToolbarComponent, FooterComponent, BottomToolbarComponent, SideComponent, LeftComponent]
})
export class NavModule {
}
