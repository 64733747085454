import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../domain/user/user';
import {DialogService} from '../../../shared/dialog.service';

@Component({
  selector: 'app-client-toolbar',
  templateUrl: './client-toolbar.component.html',
  styleUrls: ['./client-toolbar.component.scss']
})
export class ClientToolbarComponent {
  @Input()
  user: User;

  constructor(private router: Router, private dialog: DialogService) {
  }

  clickMenuItem(title, url) {
    if (!this.user) {
      this.dialog.openWithButtons(title,
        `로그인 후 ${title} 서비스를 이용할 수 있습니다.`,
        '로그인',
        '무료 회원가입')
        .then(confirm => {
          if (confirm.dismiss && confirm.dismiss === 'cancel') {
            this.router.navigateByUrl('/join');
          } else if (confirm.value) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: url}});
          }
        });
    } else if (this.user.expert) {
      this.dialog.openWithConfirmButton(
        '일반인 전용 서비스',
        '일반인 전용 서비스 입니다.\n일반인 계정으로 변경하신 후에 이용하실 수 있습니다.',
        '서비스 계정 변경')
        .then(confirm => {
          if (confirm.value) {
            this.router.navigateByUrl('/my-home/switch');
          }
        });
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
