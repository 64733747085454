import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import {isPlatformBrowser} from '@angular/common';

@Injectable({providedIn: 'root'})
export class DialogService {
  private readonly lawcostDialog;

  constructor(@Inject(PLATFORM_ID) private platformId, private snackbar: MatSnackBar) {
    if (isPlatformBrowser(this.platformId)) {
      this.lawcostDialog = Swal.mixin({
        focusConfirm: false,
        focusCancel: false,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        reverseButtons: true
      });
    }
  }

  toast(content: string) {
    this.snackbar.open(content);
  }

  open(title: string, content: string, alignLeft?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        customClass: alignLeft ? 'text-left' : ''
      });
    }
  }

  openWithConfirmButton(title: string, content: string, confirmText: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        confirmButtonText: confirmText,
      });
    }
  }

  openWithButtons(title: string, content: string, confirmText: string, cancelText: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: cancelText
      });
    }
  }

  openWithCancel(title: string, content: string, alignLeft?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        showCancelButton: true,
        customClass: alignLeft ? 'text-left' : ''
      });
    }
  }

  openWithInput(title: string, content: string, placeholder?: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        input: 'text',
        inputPlaceholder: placeholder
      });
    }
  }

  openWithInputPassword(title: string, content: string, placeholder?: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.lawcostDialog.fire({
        title: title,
        text: content,
        input: 'password',
        inputPlaceholder: placeholder
      });
    }
  }

  openWithHtml(title: string, html: string) {
    if (isPlatformBrowser(this.platformId)) {
      return Swal.fire({
        title: title,
        html: html,
        confirmButtonText: '확인',
      });
    }
  }
}
