import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner.component';
import {MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
  exports: [SpinnerComponent],
  providers: [{provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: {diameter: 70}}]
})
export class SpinnerModule {
}
