import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../domain/user/user.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {DialogService} from '../../../shared/dialog.service';
import {User} from '../../../domain/user/user';

@Component({
  selector: 'app-intro-client',
  templateUrl: './intro-client.component.html',
  styleUrls: ['./intro-client.component.scss']
})
export class IntroClientComponent implements OnDestroy {
  user;
  private readonly userWatcher: Subscription;

  constructor(private router: Router, private userService: UserService, private dialog: DialogService) {
    this.user = this.userService.getSessionUser();

    this.userWatcher = this.userService.change.subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.userWatcher.unsubscribe();
  }

  clickLink(link) {
    if (this.user) {
      this.router.navigateByUrl(link);
    } else {
      this.dialog.openWithCancel('', '로그인 후 이용하실 수 있습니다.')
        .then(confirm => {
          if (confirm.value) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: link}});
          }
        });
    }
  }
}
