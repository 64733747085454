<div fxLayout="row" fxLayoutGap.gt-xs="10px" ngClass.gt-xs="m-t-5">
  <mat-card [queryParams]="{type: 'ERROR'}" class="cursor hover bg-gray-light" routerLink="/report">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <div class="icon-circle-wrapper-s bg-white" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="mat-icon-size-32 c-gray-dark">sentiment_very_dissatisfied</mat-icon>
      </div>
      <div>오류 신고</div>
    </div>
  </mat-card>

  <mat-card [queryParams]="{type: 'SUGGEST'}" class="cursor hover bg-gray-light" routerLink="/report">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <div class="icon-circle-wrapper-s bg-white" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="mat-icon-size-32 c-gray-dark">sentiment_satisfied</mat-icon>
      </div>
      <div>기능 요청</div>
    </div>
  </mat-card>

  <mat-card (click)="clickQuestion('https://pf.kakao.com/_qvxfPxl/chat')" class="cursor hover bg-gray-light">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <div class="icon-circle-wrapper-s bg-white" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="mat-icon-size-25 c-accent">chat_bubble</mat-icon>
      </div>
      <div>카카오톡</div>
    </div>
  </mat-card>

  <mat-card (click)="clickQuestion('https://talk.naver.com/wchy8l?ref=https%3A%2F%2Flawfin.co.kr')"
            class="cursor hover bg-gray-light">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
      <div class="icon-circle-wrapper-s bg-white" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="mat-icon-size-25 c-green-light">chat_bubble</mat-icon>
      </div>
      <div>네이버톡</div>
    </div>
  </mat-card>
</div>
