import {Directive, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {MatTabGroup} from '@angular/material/tabs';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-tab-group'
})
export class MatTabGroupSwipeDirective implements OnInit {
  tabGroup;

  constructor(@Inject(PLATFORM_ID) private platformId, private el: MatTabGroup) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const Hammer = require('hammerjs');

      this.tabGroup = this.el;
      const container = this.el._elementRef.nativeElement.children.item(1);

      if (container.attributes.getNamedItem('swipe')) {
        return;
      }

      const hammer = new Hammer.Manager(container, {
        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
        recognizers: [
          [Hammer.Swipe, {direction: Hammer.DIRECTION_HORIZONTAL}]
        ]
      });

      hammer.on('swipeleft', (event) => {
        this.nextTab();
      });

      hammer.on('swiperight', (event) => {
        this.prevTab();
      });
    }
  }

  private prevTab() {
    if (this.tabGroup.selectedIndex > 0) {
      this.tabGroup.selectedIndex--;
    }
  }

  private nextTab() {
    const maxSize = this.tabGroup._tabs.length - 1;
    if (maxSize > this.tabGroup.selectedIndex) {
      this.tabGroup.selectedIndex++;
    }
  }
}
