import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {GatewayService} from '../../../../../../gateway/src/lib/gateway.service';
import {UserService} from '../../../domain/user/user.service';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss']
})
export class NotificationButtonComponent {
  badge = 0;

  constructor(private router: Router, private gateway: GatewayService, private userService: UserService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getCountNotifications();
      }
    });

    this.getCountNotifications();
  }

  private getCountNotifications() {
    if (this.userService.isLoginUser()) {
      this.gateway.get(`/notifications/count`)
        .subscribe(result => {
          this.badge = result;
        });
    }
  }
}
