<div *ngIf="visible" class="bottom-toolbar mat-elevation-z1" fxHide.gt-xs fxShow>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div (click)="toggleSideNav()" [ngClass.xs]="{'p-b-20' : isNotch, 'p-t-5' : isNotch}" class="icon-button c-black"
         fxFlex="80px">
      <mat-icon class="material-icons c-gray">dehaze</mat-icon>
      <div class="f-10 p-b-3">메뉴</div>
    </div>

<!--    <div [ngClass.xs]="{'p-b-20' : isNotch, 'p-t-5' : isNotch}" class="icon-button c-gray"-->
<!--         fxFlex="50px" routerLink="/">-->
<!--      <mat-icon class="material-icons">visibility</mat-icon>-->
<!--      <div class="f-10 p-b-3">홈</div>-->
<!--    </div>-->

    <div *ngFor="let menu of menus"
         [ngClass]="{'p-b-20' : isNotch, 'p-t-5' : isNotch, 'menu-item-active': menu.active, 'menu-item' : !menu.active}"
         [routerLink]="menu.link" class="icon-button"
         fxFlex>
      <mat-icon class="material-icons">{{menu.icon}}</mat-icon>
      <div class="f-10 p-b-3 menu-name">{{menu.label}}</div>
    </div>
  </div>
</div>
