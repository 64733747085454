import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services/AuthenticationService';
import {DialogService} from '../shared/dialog.service';
import {SpinnerService} from '../shared/spinner/spinner.service';
import {catchError, finalize} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';

@Injectable({providedIn: 'root'})
export class BasicAuthInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private authenticationService: AuthenticationService,
              private dialog: DialogService,
              private spinnerService: SpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.change(true);

    if (!environment.production) {
      if (isPlatformBrowser(this.platformId)) {
        const currentUser = JSON.parse(localStorage.getItem('principal'));

        if (currentUser && currentUser.basic) {
          request = request.clone({
            setHeaders: {
              Authorization: `Basic ${currentUser.basic}`
            },
            withCredentials: true
          });
        } else {
          request = request.clone({withCredentials: true});
        }
      }
    } else {
      request = request.clone({withCredentials: true});
    }

    return next.handle(request)
      .pipe(catchError(errorResponse => {
        if (errorResponse) {
          let errorMessage = errorResponse.error.message || errorResponse.error.text || errorResponse.message;

          if (errorResponse.status === 200) {
            this.dialog.toast(errorMessage);
            return EMPTY;
          }

          if (errorResponse.status === 401 || errorMessage.toString().includes('UNAUTHORIZED')) {
            if (this.router.url === '/login') {
              errorMessage = '아이디 또는 비밀번호를 잘못 입력하셨습니다.';
            } else {
              this.router.navigateByUrl('/login');
              errorMessage = '로그인이 필요합니다.';
            }
          } else if (errorResponse.status === 400) {
            this.dialog.toast(errorMessage);
          } else {
            this.dialog.open('', '오류가 발생하였습니다. 잠시후 다시 시도해주세요.\n' +
              '일시적인 네트워크 장애일수 있으나 운영팀에서 문제를 확인하고 있습니다.\n' +
              '문제가 지속될 경우 고객센터로 문의해주세요. 02-555-0324');
          }

          return throwError(errorMessage);
        }
      }), finalize(() => {
        this.spinnerService.change(false);
      }));
  }
}
