import {Component, EventEmitter, Inject, Output, PLATFORM_ID} from '@angular/core';
import {UserService} from '../../domain/user/user.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {isPlatformBrowser} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {NavService} from '../nav.service';
import {User} from '../../domain/user/user';
import {BottomToolbarService} from './bottom-toolbar.service';

@Component({
  selector: 'app-bottom-toolbar',
  templateUrl: './bottom-toolbar.component.html',
  styleUrls: ['./bottom-toolbar.component.scss']
})
export class BottomToolbarComponent {
  @Output() toggle = new EventEmitter();
  isNotch = false;
  isLogin = false;
  isHome = false;
  visible = true;
  menus;
  private pageRole = 'CLIENT';

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private userService: UserService,
              private deviceService: DeviceDetectorService,
              private bottomToolbarService: BottomToolbarService,
              private navService: NavService) {
    this.userService.change.subscribe((user: User) => {
      this.isLogin = this.userService.isLoginUser();
      this.initMenus();
    });

    this.navService.change.subscribe((role: string) => {
      this.pageRole = role;
      this.initMenus();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isHome = this.router.url === '/';
        this.initMenus();
      }
    });

    this.bottomToolbarService.visible.subscribe(visible => {
      this.visible = visible;
    });

    this.isHome = this.router.url === '/';
    this.initMenus();

    this.isNotch = this.checkNotchIphone();
  }

  toggleSideNav(): void {
    this.toggle.emit('left');
  }

  checkNotchIphone(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (this.deviceService.getDeviceInfo().os !== 'iOS') {
        return false;
      }
      const ratio = window.devicePixelRatio || 1;

      const screen = {
        width: window.screen.width * ratio,
        height: window.screen.height * ratio
      };

      if (screen.width === 1125 && screen.height === 2436) {
        return true;
      } else if (screen.width === 828 && screen.height === 1797) {
        return true;
      } else if (screen.width === 1242 && screen.height === 2688) {
        return true;
      }
    }

    return false;
  }

  private initMenus() {
    const isExpert = this.pageRole === 'EXPERT';

    if (this.isHome) {
      this.menus = this.getHomeMenu(isExpert);
    } else if (this.router.url.includes('/certification')) {
      this.menus = this.getCertificationMenu();
    }
    // else if (this.router.url.includes('/my-house/open') || this.router.url.includes('/my-house/open-info') || this.router.url.includes('/my-house/register/open')) {
    //   this.menus = this.getOpenHouseMenu();
    // }
    // else if (this.router.url.includes('/my-house')) {
    //   this.menus = this.getMyHouseMenu();
    // }
    else if (this.router.url.includes('/case-selector')) {
      this.menus = isExpert ? this.getEstimateMenu() : this.getApplicationMenu(isExpert);
    } else if (this.router.url.includes('/applications')) {
      this.menus = this.getApplicationMenu(isExpert);
    } else if (this.router.url.includes('/cost') || this.router.url.includes('/estimates') || this.router.url.includes('/fee-tables') || this.router.url.includes('/auto-estimates')) {
      this.menus = isExpert ? this.getEstimateMenu() : this.getApplicationMenu(isExpert);
    } else if (this.router.url.includes('/briefcases') || this.router.url.includes('/calendar')) {
      this.menus = this.getBriefcaseMenu(isExpert);
    } else if (this.router.url.includes('/introduce') || this.router.url.includes('/how-to') || this.router.url.includes('/event') || this.router.url.includes('/notice')) {
      this.menus = this.getIntroduceMenu();
    } else if (this.router.url.includes('/my-home')) {
      this.menus = this.getMyHomeMenu(isExpert);
    }

    this.menus.forEach(menu => {
      menu.active = false;
      menu.active = this.router.url.indexOf(`/${menu.link.split('/')[1]}`) === 0;
      if (menu.active) {
        if (this.router.url.includes('/introduce') || this.router.url.includes('/my-house') || this.router.url.includes('/certification')) {
          menu.active = this.router.url === menu.link;
        }
      }
    });
  }

  private getHomeMenu(isExpert: boolean) {
    if (isExpert) {
      return this.isLogin ? [
        {icon: 'account_circle', label: this.pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈', link: '/my-home'},
        {icon: 'inbox', label: '사건관리', link: '/briefcases'},
        {icon: 'live_help', label: '상담관리', link: '/estimates'},
      ] : [
        {icon: 'info', label: '서비스 소개', link: '/introduce/firm'},
        {icon: 'help', label: '이용방법', link: '/how-to'}
        // {icon: 'sentiment_very_satisfied', label: '이벤트', link: '/event'}
        //<!--      Todo: 이용방법 링크 연결 수정 /guid -> /how-to-->
      ];
    } else {
      return this.isLogin ? [
        {icon: 'sync_alt', label: '사건진행', link: '/briefcases'},
        {icon: 'live_help', label: '상담신청', link: '/case-selector'},
        {icon: 'format_list_bulleted', label: '내용증명', link: '/certification/list'},
      ] : [
        {icon: 'info', label: '서비스 소개', link: '/introduce'},
        {icon: 'help', label: '이용방법', link: '/how-to'}
        // {icon: 'sentiment_very_satisfied', label: '이벤트', link: '/event'}
        // <!--      Todo: 이용방법 링크 연결 수정 /guid -> /how-to-->
      ];
    }
  }

  private getCertificationMenu() {
    return [
      {icon: 'drive_file_rename_outline', label: '신규작성', link: '/certification'},
      {icon: 'format_list_bulleted', label: '작성내역', link: '/certification/list'},
      {icon: 'help', label: '이용방법', link: '/certification/info'},
      {icon: 'sync_alt', label: '사건진행', link: '/briefcases'}
    ];
  }

  // private getOpenHouseMenu() {
  //   return [
  //     {icon: 'room', label: '마이 부동산', link: '/my-house/subscribes'},
  //     {icon: 'report_problem', label: '오픈부동산 보기', link: '/my-house/open'},
  //     {icon: 'info', label: '오픈부동산 안내', link: '/my-house/open-info'}
  //   ];
  // }

  // private getMyHouseMenu() {
  //   return [
  //     {icon: 'room', label: '마이 부동산', link: '/my-house/subscribes'},
  //     {icon: 'info', label: '마이부동산 안내', link: '/my-house/info'},
  //     {icon: 'report_problem', label: '오픈부동산 보기', link: '/my-house/open'}
  //   ];
  // }

  private getApplicationMenu(isExpert: boolean) {
    if (isExpert) {
      return [
        {icon: 'inbox', label: '사건관리', link: '/briefcases'},
        {icon: 'format_list_bulleted', label: '상담내역', link: '/estimates'},
        {icon: 'live_help', label: '상담요청', link: '/applications'},
        {icon: 'calculate', label: '보수표 설정', link: '/fee-tables'}
      ];
    }

    return [
      {icon: 'format_list_bulleted', label: '상담내역', link: '/applications'},
      {icon: 'live_help', label: '상담신청', link: '/case-selector'},
      {icon: 'sync_alt', label: '사건진행', link: '/briefcases'}
    ];
  }

  private getEstimateMenu() {
    return [
      {icon: 'format_list_bulleted', label: '상담내역', link: '/estimates'},
      {icon: 'calculate', label: '보수표 설정', link: '/fee-tables'},
      {icon: 'change_circle', label: '자동견적 설정', link: '/auto-estimates'}
    ];
  }


  private getMyHomeMenu(isExpert: boolean) {
    if (isExpert) {
      return [
        {icon: 'account_circle', label: this.pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈', link: '/my-home'},
        {icon: 'inbox', label: '사건관리', link: '/briefcases'},
        {icon: 'live_help', label: '상담관리', link: '/estimates'},
      ];
    }

    return [
      {icon: 'account_circle', label: this.pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈', link: '/my-home'},
      {icon: 'live_help', label: '상담신청', link: '/case-selector'},
      {icon: 'drive_file_rename_outline', label: '내용증명', link: '/certification'},
    ];
  }


  private getBriefcaseMenu(isExpert: boolean) {
    if (isExpert) {
      return [
        {icon: 'account_circle', label: this.pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈', link: '/my-home'},
        {icon: 'inbox', label: '사건관리', link: '/briefcases'},
        {icon: 'format_list_bulleted', label: '상담내역', link: '/estimates'}
      ];
    } else {
      return [
        {icon: 'account_circle', label: this.pageRole === 'EXPERT' ? '마이 로팀' : '마이 홈', link: '/my-home'},
        {icon: 'sync_alt', label: '사건진행', link: '/briefcases'},
        {icon: 'live_help', label: '상담신청', link: '/case-selector'}
      ];
    }
  }

  private getIntroduceMenu() {
    return [
      {icon: 'person', label: '일반인 서비스', link: '/introduce'},
      {icon: 'work', label: '전문가 서비스', link: '/introduce/firm'},
      {icon: 'help', label: '이용방법', link: '/how-to'}
      // <!--      Todo: 이용방법 링크 연결 수정 /guid -> /how-to-->
    ];
  }
}
