import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Injectable({providedIn: 'root'})
export class NavigatorService {
  private previousPath;
  private currentPath;

  constructor(@Inject(PLATFORM_ID) private platformId, private router: Router) {
  }

  public getPreviousState() {
    if (isPlatformBrowser(this.platformId)) {
      const previousPath = sessionStorage.getItem('previous');
      if (previousPath && previousPath !== 'undefined' && previousPath !== 'null') {
        return previousPath;
      } else {
        return '/';
      }
    }
  }

  public setPreviousState(path: any) {
    this.previousPath = this.currentPath;
    this.currentPath = path;

    if (isPlatformBrowser(this.platformId)) {
      if (this.previousPath && this.previousPath.includes('/login')) {
        sessionStorage.setItem('previous', '/');
      } else {
        sessionStorage.setItem('previous', this.previousPath);
      }
    }
  }

  public navigatePrevious() {
    if (isPlatformBrowser(this.platformId)) {
      const previousPath = sessionStorage.getItem('previous');
      if (previousPath && previousPath !== 'undefined' && previousPath !== 'null') {
        this.router.navigateByUrl(previousPath);
      } else {
        this.router.navigate(['/']);
      }
    }
  }
}
