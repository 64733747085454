import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class NavService {
  change = new Subject();
  role = 'CLIENT';

  changeRole(role) {
    this.role = role;
    this.change.next(role);
  }

  getCurrentRole() {
    return this.role;
  }
}
