import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import * as moment from 'moment';
import {isPlatformBrowser} from '@angular/common';
import {NativeAppService} from '../../_services/native-app.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DialogService} from '../../shared/dialog.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  readonly appStoreSrc = 'assets/img/store/AppStore.png';
  readonly googlePlaySrc = 'assets/img/store/GooglePlay.png';
  currentYear = moment().get('year');
  showFooterInfo = false;
  isNativeApp = false;
  showAppStoreLink = false;
  showPlayStoreLink = false;
  showHowTo = false;

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private mobile: NativeAppService,
              private deviceService: DeviceDetectorService,
              private dialog: DialogService) {
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.showAppStoreLink = this.deviceService.isDesktop() || deviceInfo.os === 'iOS';
    this.showPlayStoreLink = this.deviceService.isDesktop() || deviceInfo.os === 'Android';

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showFooterInfo = event.url === '/';
      });

    this.mobile.change.subscribe(() => {
      this.isNativeApp = this.mobile.isNativeApp();
    });
  }

  openAppStore() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.mobile.isiOS()) {
        window.open('itms-apps://itunes.apple.com/kr/app/apple-store/id1367038224?mt=8');
      } else {
        window.open('https://itunes.apple.com/kr/app/apple-store/id1367038224?mt=8');
      }
    }
  }

  openGooglePlay() {
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://play.google.com/store/apps/details?id=kr.co.lawalliance.lawcost');
    }
  }
}
