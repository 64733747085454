import {Component} from '@angular/core';
import {GatewayService} from '../../../../../../gateway/src/lib/gateway.service';
import {DialogService} from '../../../shared/dialog.service';
import {UserService} from '../../../domain/user/user.service';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.scss']
})
export class RightComponent {
  tabIndex = 0;
  bookmarks = [];
  recentCases = [];
  searchedCases = [];

  constructor(private userService: UserService, private gateway: GatewayService, private dialog: DialogService) {
    this.getList();
  }

  changeTab(index) {
    this.tabIndex = index;
    this.getList();
  }

  getList() {
    if (this.userService.hasSession()) {
      if (this.tabIndex === 0) {
        this.gateway.get('/bookmarks')
          .subscribe(result => {
            if (result.success) {
              this.bookmarks = result.data;
            }
          });
      } else {
        this.gateway.get('/recent-cases')
          .subscribe(result => {
            if (result.success) {
              this.recentCases = this.reduceLawCases(result.data);
            }
          });
      }
    }
  }

  reduceLawCases(lawCases) {
    return lawCases.reduce((o, cur) => {
      const occurs = o.reduce((n, item, i) => {
        return (item.subcategory === cur.subcategory) ? i : n;
      }, -1);

      if (occurs >= 0) {
        o[occurs].lawCases = o[occurs].lawCases.concat(cur);
      } else {
        const obj = {subcategory: cur.subcategory, name: `${cur.category} > ${cur.subcategory}`, lawCases: [cur]};
        o = o.concat([obj]);
      }
      return o;
    }, []);
  }

  search(event, keyword) {
    if (event.code === 'Enter') {
      this.gateway.get('/catalog/search', {name: keyword})
        .subscribe(result => {
          if (result.success) {
            this.searchedCases = this.reduceLawCases(result.data);

            if (this.searchedCases.length === 0) {
              this.dialog.toast('존재하지 않는 사건명입니다.');
            }
          }
        });
    }
  }

  closeSearch(input) {
    input.value = '';
    this.searchedCases = [];
  }
}
