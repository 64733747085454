import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {GatewayService} from '../../../../gateway/src/lib/gateway.service';
import {isPlatformBrowser} from '@angular/common';
import {Subject} from 'rxjs';

declare var Android: any;
declare var window: any;

@Injectable({providedIn: 'root'})
export class NativeAppService {
  change = new Subject();
  private osName;

  constructor(@Inject(PLATFORM_ID) private platformId, private gateway: GatewayService) {
    if (isPlatformBrowser(this.platformId)) {
      this.osName = JSON.parse(sessionStorage.getItem('osName'));
    }
  }

  setOS(osName) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('osName', 'true');
    }

    this.osName = osName;
    this.change.next(osName);
  }

  isNativeApp() {
    return this.osName !== undefined;
  }

  isAndroid() {
    return this.osName === 'ANDROID';
  }

  isiOS() {
    return this.osName === 'iOS';
  }

  getPushInfo() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isAndroid()) {
        Android.getPushInfo();
      } else if (this.isiOS()) {
        try {
          window.webkit.messageHandlers.getPushInfo.postMessage('');
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  sendPushInfo(fcmKey, receiveNotification) {
    const pushInfo = {fcmKey, receiveNotification};

    this.gateway.put('/users/fcm', pushInfo)
      .subscribe();
  }

  toggleAndroidRefresh(enabled: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isAndroid()) {
        Android.toggleSwipeRefresh(enabled);
      }
    }
  }
}
