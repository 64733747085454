import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IntroComponent} from './intro.component';
import {IntroConsultingCaseComponent} from './intro-consulting-case/intro-consulting-case.component';
import {IntroEventComponent} from './intro-event/intro-event.component';
import {IntroNoticeComponent} from './intro-notice/intro-notice.component';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {IntroRoutingModule} from './intro-routing.module';
import {IntroClientComponent} from './intro-client/intro-client.component';
import {IntroExpertComponent} from './intro-expert/intro-expert.component';
import {IntroExpertBannerComponent} from './intro-expert/intro-expert-banner/intro-expert-banner.component';
import {DirectiveModule} from '../../_directive/directive.module';
import {IntroReportComponent} from './intro-report/intro-report.component';
import {EventFinderService} from '../../../../../domain/src/lib/event/event-finder.service';
import {IntroClientBannerComponent} from './intro-client/intro-client-banner/intro-client-banner.component';
import {IntroApplicationComponent} from './intro-application/intro-application.component';
import {PortalModule} from '@angular/cdk/portal';
import {SwiperModule} from '../../shared/swiper/swiper.module';

@NgModule({
  imports: [
    CommonModule,
    IntroRoutingModule,
    FlexLayoutModule,
    MatIconModule,
    MatTooltipModule,
    DirectiveModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatButtonModule,
    MatTabsModule,
      PortalModule,
      SwiperModule
  ],
  declarations: [
    IntroComponent,
    IntroClientComponent,
    IntroExpertComponent,
    IntroConsultingCaseComponent,
    IntroEventComponent,
    IntroNoticeComponent,
    IntroExpertBannerComponent,
    IntroReportComponent,
    IntroClientBannerComponent,
    IntroApplicationComponent
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EventFinderService]
})

export class IntroModule {
}
