import {Component} from '@angular/core';
import {UserService} from '../../../../domain/user/user.service';
import {DialogService} from '../../../../shared/dialog.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-intro-expert-banner',
  templateUrl: './intro-expert-banner.component.html',
  styleUrls: ['./intro-expert-banner.component.scss']
})
export class IntroExpertBannerComponent {
  constructor(private router: Router, private userService: UserService, private dialog: DialogService) {
  }

  clickLinkItem(title, url) {
    const user = this.userService.getSessionUser();

    if (!user) {
      this.dialog.openWithButtons(title,
        `로그인 후 ${title} 서비스를 이용할 수 있습니다.`,
        '로그인',
        '무료 회원가입')
        .then(confirm => {
          if (confirm.dismiss && confirm.dismiss === 'cancel') {
            this.router.navigateByUrl('/join');
          } else if (confirm.value) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: url}});
          }
        });
    } else if (!user.expert) {
      if (!user.switchable) {
        this.dialog.openWithButtons(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '소속원 신청',
          '전문가 등록')
          .then(confirm => {
            if (confirm.dismiss && confirm.dismiss === 'cancel') {
              this.router.navigateByUrl('/contract');
            } else if (confirm.value) {
              this.router.navigateByUrl('/apply-firm');
            }
          });
      } else {
        this.dialog.openWithConfirmButton(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '서비스 계정 변경')
          .then(confirm => {
            if (confirm.value) {
              this.router.navigateByUrl('/my-home/switch');
            }
          });
      }
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
