import {Component, Input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent {
  @Input()
  rightDrawer: MatDrawer;

  constructor() {
  }

  toggle() {
    this.rightDrawer.toggle();
  }
}
