import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTabGroupSwipeDirective} from './mat-tab-group-swipe.directive';
import {InputNumberDirective} from './input-number.directive';
import {MatTooltipDirective} from './mat-tooltip.directive';
import {PreviousDirective} from './privious.directive';

@NgModule({
  declarations: [MatTabGroupSwipeDirective, InputNumberDirective, MatTooltipDirective, PreviousDirective],
  imports: [CommonModule],
  exports: [MatTabGroupSwipeDirective, InputNumberDirective, MatTooltipDirective, PreviousDirective]
})
export class DirectiveModule {
}
