import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {GatewayService} from '../../../../../../gateway/src/lib/gateway.service';
import {User} from '../../../domain/user/user';
import {UserService} from '../../../domain/user/user.service';
import {DialogService} from '../../../shared/dialog.service';

@Component({
  selector: 'app-switch-service-button',
  templateUrl: './switch-service-button.component.html',
  styleUrls: ['./switch-service-button.component.scss']
})
export class SwitchServiceButtonComponent implements OnChanges {
  @Input()
  user: User;
  firms = [];
  currentFirmName;

  constructor(private router: Router, private gateway: GatewayService, private userService: UserService, private dialog: DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentFirmName = this.user.expert ? this.user.firmName : '';
    if (this.currentFirmName.length > 9) {
      this.currentFirmName = this.currentFirmName.substring(0, 10);
    }
  }

  openMenu() {
    this.gateway.get('/firms')
      .subscribe(result => {
        if (result.success) {
          this.firms = result.data.filter(firm => !firm.expired);
        }
      });
  }

  switchFirm(firmId) {
    this.gateway.put('/users/firms', firmId)
      .subscribe(result => {
        if (result.success) {
          this.router.navigateByUrl('/');
          this.userService.getUser();
        } else {
          this.dialog.open('전문가 계정 차단', result.message);
        }
      });
  }

  logout() {
    this.userService.logout();
  }
}
