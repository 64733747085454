import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {User} from '../../domain/user/user';
import {Subscription} from 'rxjs/internal/Subscription';
import {UserService} from '../../domain/user/user.service';
import {NavService} from '../nav.service';
import {NavigationEnd, Router} from '@angular/router';
import {DialogService} from '../../shared/dialog.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  readonly mobileLogoSrc = 'assets/img/bi/lawfin-bi-xs.png';
  @Input() rightDrawer;
  @Output() toggleRightDrawer = new EventEmitter();
  user: User;
  pageRole = 'CLIENT';
  showRoleSwitch = false;
  private userWatcher: Subscription;

  constructor(private router: Router,
              private userService: UserService,
              private dialog: DialogService,
              private nav: NavService) {
    this.nav.change.subscribe((role: string) => {
      this.pageRole = role;
    });

    this.userWatcher = this.userService.change.subscribe((user: User) => {
      this.user = user;
      if (this.user) {
        this.switchRole(this.user.expert ? 'EXPERT' : 'CLIENT');
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showRoleSwitch = event.url === '/';
      }
    });
  }

  ngOnInit(): void {
    if (!this.user) {
      this.userService.getCurrentUser()
        .subscribe(user => {
          if (user) {
            this.user = user;
            this.switchRole(this.user.expert ? 'EXPERT' : 'CLIENT');
          }
        });
    }
  }

  clickQuestion() {
    this.dialog.openWithButtons('', '로핀 서비스 이용에 대한 문의를 하실 수 있습니다.\n법률상담은 무료상담 메뉴를 이용해주세요.', '문의하기', '취소')
      .then(confirm => {
        if (confirm.value) {
          this.router.navigateByUrl('/question');
        }
      });
  }

  clickMenuItem(title, url) {
    if (!this.user) {
      this.dialog.openWithButtons(title,
        `로그인 후 ${title} 서비스를 이용할 수 있습니다.`,
        '로그인',
        '무료 회원가입')
        .then(confirm => {
          if (confirm.dismiss && confirm.dismiss === 'cancel') {
            this.router.navigateByUrl('/join');
          } else if (confirm.value) {
            this.router.navigate(['/login'], {queryParams: {returnUrl: url}});
          }
        });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  clickExpertMenuItem(title, url) {
    if (!this.user.expert) {
      this.dialog.toast(`${title}은 전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.`);
      return;
    }

    this.router.navigateByUrl(url);
  }

  switchRole(role) {
    this.nav.changeRole(role);
  }

  openRightDrawer(): void {
    if (this.user.expert) {
      this.toggleRightDrawer.emit();
    } else {
      if (!this.user.switchable) {
        this.dialog.openWithButtons(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '소속원 신청',
          '전문가 등록')
          .then(confirm => {
            if (confirm.dismiss && confirm.dismiss === 'cancel') {
              this.router.navigateByUrl('/contract');
            } else if (confirm.value) {
              this.router.navigateByUrl('/apply-firm');
            }
          });
      } else {
        this.dialog.openWithConfirmButton(
          '전문가 전용 서비스',
          '전문가 전용 서비스 입니다.\n전문가 계정으로 변경하신 후에 이용하실 수 있습니다.',
          '서비스 계정 변경')
          .then(confirm => {
            if (confirm.value) {
              this.router.navigateByUrl('/my-home/switch');
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.userWatcher.unsubscribe();
  }
}
