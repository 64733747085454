import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BottomButtonSheetComponent} from './bottom-button-sheet.component';
import {MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatButtonModule} from '@angular/material/button';
import {NoopScrollStrategy} from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    BottomButtonSheetComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatBottomSheetModule,
    MatButtonModule
  ],
  providers: [
    {provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: {hasBackdrop: false, scrollStrategy: new NoopScrollStrategy()}}
  ]
})
export class BottomButtonSheetModule {
}
