import {Directive, ElementRef, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';

@Directive({
  selector: '[matTooltip]'
})
export class MatTooltipDirective {

  constructor(@Inject(PLATFORM_ID) private platformId, private el: ElementRef, private tooltip: MatTooltip) {
    if (isPlatformBrowser(this.platformId)) {
      const tooltipElement = this.el.nativeElement;

      tooltipElement.addEventListener('click', () => {
        this.tooltip.toggle();
      });
    }
  }
}
