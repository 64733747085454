import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SpinnerService {
  private countProcessing = [];
  private event = new BehaviorSubject(false);

  listener(): Observable<boolean> {
    return this.event.asObservable();
  }

  change(processingHttp) {
    if (processingHttp) {
      this.countProcessing.push(true);
    } else {
      this.countProcessing.pop();
    }

    if (this.countProcessing.length > 0) {
      this.event.next(true);
    } else if (this.countProcessing.length === 0) {
      this.event.next(false);
    }
  }
}
