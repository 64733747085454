import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-intro-report',
  templateUrl: './intro-report.component.html',
  styleUrls: ['./intro-report.component.scss']
})
export class IntroReportComponent {

  constructor(@Inject(PLATFORM_ID) private platformId, private router: Router, private dialog: DialogService) {
  }

  clickQuestion(url) {
    this.dialog.openWithButtons('', '로핀 서비스 이용에 대한 문의를 하실 수 있습니다.\n법률상담은 무료상담 메뉴를 이용해주세요.', '문의하기', '취소')
      .then(confirm => {
        if (confirm.value) {
          if (isPlatformBrowser(this.platformId)) {
            window.open(url, '1 : 1', 'scrollbars=1, resizable=1, width=486, height=745');
          }
        }
      });
  }
}
