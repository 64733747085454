import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import {GatewayService} from '../../../../../gateway/src/lib/gateway.service';
import {EMPTY, Observable, Subject} from 'rxjs';
import {User} from './user';
import {AuthenticationService} from '../../_services/AuthenticationService';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class UserService implements OnDestroy {
  change = new Subject();
  private user: User;

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private gateway: GatewayService,
              private authenticationService: AuthenticationService) {
    if (isPlatformBrowser(this.platformId)) {
      const sessionUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (sessionUser) {
        this.user = sessionUser;
      }

      this.checkSession()
        .subscribe(result => {
          if (result) {
            this.getUser();
          } else {
            this.authenticationService.autoLogin()
              .subscribe(() => {
                this.getUser();
              });
          }
        });
    }
  }

  checkSession() {
    return this.gateway.get('/session');
  }

  hasSession() {
    return this.user !== undefined;
  }

  getSessionUser(): User {
    return this.user;
  }

  public getUser(routePath?) {
    this.gateway.get(`/users`)
      .subscribe((user: User) => {
        this.user = user;
        if (user.needChangePassword) {
          this.router.navigateByUrl('/password/info');
          return;
        }

        this.change.next(this.user);

        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem('currentUser', JSON.stringify(this.user));
        }
        if (routePath) {
          this.router.navigateByUrl(routePath);
        }
      });
  }

  getCurrentUser(): Observable<User> {
    if (this.isLoginUser()) {
      return this.gateway.get(`/users`);
    }

    return EMPTY;
  }

  isLoginUser(): boolean {
    return this.user !== undefined;
  }

  login(routePath?): void {
    this.authenticationService.login()
      .subscribe(success => {
        if (success) {
          this.getUser(routePath);
        }
      });
  }

  logout(): void {
    if (this.isLoginUser()) {
      this.user = undefined;
      this.change.next(undefined);
      this.authenticationService.logout();

      if (isPlatformBrowser(this.platformId)) {
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem('snsLogin');
      }
    }
  }

  postponeChangePassword() {
    this.user.needChangePassword = false;
  }

  ngOnDestroy(): void {
    this.change.unsubscribe();
  }
}
