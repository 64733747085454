import {Component, Input} from '@angular/core';
import {User} from '../../../domain/user/user';
import {MatDrawer} from '@angular/material/sidenav';
import {UserService} from '../../../domain/user/user.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.scss']
})
export class LeftComponent {
  @Input()
  drawer: MatDrawer;
  user: User;
  userType;

  constructor(private router: Router, private userService: UserService) {
    this.userService.change.subscribe((user: User) => {
      this.user = user;
      if (this.user && !this.user.picture) {
        this.user.picture = this.user.expert ? 'assets/img/profile/expert.png' : 'assets/img/profile/user.png';
      }
      this.checkUserType();
    }, () => {
      this.user = undefined;
      this.checkUserType();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.drawer.close();
      }
    });
  }

  toggle() {
    this.drawer.toggle().then(() => {
      this.checkUserType();
    });
  }

  private checkUserType() {
    if (!this.user) {
      this.userType = 'ANONYMOUS';
    } else if (this.user.expert) {
      this.userType = 'EXPERT';
    } else if (!this.user.expert) {
      this.userType = 'CLIENT';
    } else {
      this.userType = 'ANONYMOUS';
    }
  }
}
