import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {GatewayService} from '../../../../gateway/src/lib/gateway.service';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {NativeAppService} from './native-app.service';
import {catchError, map} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private gateway: GatewayService,
              private nativeAppService: NativeAppService) {
  }

  autoLogin() {
    if (isPlatformBrowser(this.platformId)) {
      const loginForm = JSON.parse(localStorage.getItem('rememberUser'));
      if (loginForm) {
        sessionStorage.setItem('login', JSON.stringify(loginForm));
        return this.login();
      }
    }

    return EMPTY;
  }

  login() {
    if (isPlatformBrowser(this.platformId)) {
      const loginForm = JSON.parse(sessionStorage.getItem('login'));

      if (loginForm.username) {
        return this.gateway.postForm('/login', loginForm)
          .pipe(map(() => {
            if (loginForm.remember) {
              this.savePrincipal(loginForm);
            }
            sessionStorage.removeItem('login');

            this.nativeAppService.getPushInfo();

            return true;
          }), catchError(err => of(sessionStorage.removeItem('login'))));
      }
    }
  }

  logout() {
    this.gateway.get('/logout')
      .subscribe(() => {
        this.clearSessionAndMoveRootPage();
      }, error => {
        console.error(error);
        this.clearSessionAndMoveRootPage();
      });
  }

  changeAuth(username: string, password: string) {
    if (isPlatformBrowser(this.platformId)) {
      const rememberUser = JSON.parse(localStorage.getItem('rememberUser'));
      if (rememberUser) {
        rememberUser.username = username;
        rememberUser.password = password;

        sessionStorage.setItem('rememberUser', JSON.stringify(rememberUser));
      }
    }
  }

  private savePrincipal(loginForm): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('rememberUser', JSON.stringify(loginForm));
    }
  }

  private clearSessionAndMoveRootPage() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('rememberUser');
      if (!environment.production) {
        localStorage.removeItem('principal');
      }
    }

    if (this.router.url !== '/login') {
      this.router.navigate(['/']);
    }
  }
}
