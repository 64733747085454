import {AfterViewInit, Component, HostListener, ViewChild} from '@angular/core';
import {Router, Scroll} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MatDrawer} from '@angular/material/sidenav';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('rightDrawer', {static: true})
  rightDrawer: MatDrawer;

  constructor(private router: Router, private viewportScroller: ViewportScroller) {
    this.router.events.pipe(
      filter((event) => event instanceof Scroll))
      .subscribe((e: Scroll) => {
        this.viewportScroller.scrollToPosition([0, 1]);
        this.rightDrawer.close().then();
      });
  }

  ngAfterViewInit(): void {
    this.rightDrawer.openedStart.subscribe(() => {
      this.checkSafeScroll();
    });
  }

  @HostListener('window:scroll', [])
  windowScroll() {
    if (this.rightDrawer.opened) {
      this.checkSafeScroll();
    }
  }

  private checkSafeScroll() {
    const scrollPosition = this.viewportScroller.getScrollPosition();
    if (scrollPosition[1] <= 0) {
      this.viewportScroller.scrollToPosition([0, 1]);
    }
  }
}
