import {Component} from '@angular/core';
import {SpinnerService} from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  showSpinner = true;

  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.listener().subscribe(result => {
      this.showSpinner = result;
    });
  }
}
