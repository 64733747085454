import {Component} from '@angular/core';
import {NavService} from '../../nav/nav.service';
import {ComponentPortal, Portal} from '@angular/cdk/portal';
import {IntroClientComponent} from './intro-client/intro-client.component';
import {IntroExpertComponent} from './intro-expert/intro-expert.component';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
  pageRole = 'CLIENT';
  selectedPortal: Portal<any>;

  constructor(private navService: NavService) {
    this.pageRole = this.navService.getCurrentRole();

    this.navService.change.subscribe((role: string) => {
      this.pageRole = role;
      this.selectPortal();
    });

    this.selectPortal();
  }

  private selectPortal() {
    this.selectedPortal = this.pageRole === 'CLIENT' ? new ComponentPortal(IntroClientComponent) : new ComponentPortal(IntroExpertComponent);
  }
}
