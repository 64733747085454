import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

export class RestResponse {
  success: boolean;
  message: string;
  data?: any;
}

@Injectable({providedIn: 'root'})
export class GatewayService {
  private readonly API_HOST;

  constructor(@Inject('API_HOST') private apiHost: string, private httpClient: HttpClient) {
    this.API_HOST = apiHost;
  }

  public getBaseUrl() {
    return this.API_HOST;
  }

  public get(path: string, params?) {
    return this.httpClient.get<RestResponse | any>(this.createRequestUrl(path), {params});
  }

  public put(path: string, params?) {
    return this.httpClient.put<RestResponse | any>(this.createRequestUrl(path), params);
  }

  public post(path: string, params?) {
    return this.httpClient.post<RestResponse | any>(this.createRequestUrl(path), params);
  }

  public delete(path: string, params?) {
    return this.httpClient.delete<RestResponse | any>(this.createRequestUrl(path), {params});
  }

  public postForm(path: string, params?) {
    const formData = new FormData();
    this.createFormData(formData, params);
    return this.httpClient.post<RestResponse | any>(this.createRequestUrl(path), formData);
  }

  public putForm(path: string, params?) {
    const formData = new FormData();
    this.createFormData(formData, params);
    return this.httpClient.put<RestResponse | any>(this.createRequestUrl(path), formData);
  }

  private createRequestUrl(path: string) {
    return path.includes('http') ? path : `${this.API_HOST}${path}`;
  }

  private createFormData(formData, data, parentKey?) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        if (parentKey && parentKey.includes('[')) {
          this.createFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key);
        } else {
          this.createFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }
      });
    } else {
      if (data) {
        formData.append(parentKey, data);
      }
    }
  }
}
