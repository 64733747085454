import {NavigationEnd, NavigationStart, Router, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {SpinnerService} from './shared/spinner/spinner.service';
import {NavigatorService} from './_services/navigator.service';
import {BottomToolbarService} from './nav/bottom-toolbar/bottom-toolbar.service';
import {MemberGuard} from './_guards/member-guard.service';
import {IntroComponent} from './page/intro/intro.component';

const routes: Routes = [
  {path: '', component: IntroComponent},
  {path: 'error/guard', loadChildren: () => import('./page/error/guard/guard.module').then(module => module.GuardModule)},
  {path: 'join', loadChildren: () => import('./page/join/join.module').then(module => module.JoinModule)},
  {path: 'join/sns', loadChildren: () => import('./page/join/join-sns/join-sns.module').then(module => module.JoinSnsModule)},
  {path: 'login', loadChildren: () => import('./page/login/login.module').then(module => module.LoginModule)},
  {path: 'forgot', loadChildren: () => import('./page/forgot/forgot.module').then(m => m.ForgotModule)},
  {path: 'terms', loadChildren: () => import('./page/terms/terms.module').then(m => m.TermsModule)},
  {path: 'introduce', loadChildren: () => import('./page/help/introduce/introduce.module').then(m => m.IntroduceModule)},
  {path: 'introduce/firm', loadChildren: () => import('./page/help/introduce/introduce.module').then(m => m.IntroduceModule)},
  {path: 'how-to', loadChildren: () => import('./page/help/how-to/how-to.module').then(m => m.HowToModule)},
  {path: 'question', loadChildren: () => import('./page/question/question.module').then(m => m.QuestionModule), canActivate: [MemberGuard]},
  {path: 'guide', loadChildren: () => import('./page/guide/guide.module').then(m => m.GuideModule)},
  {path: 'notice', loadChildren: () => import('./page/notice/notice.module').then(m => m.NoticeModule)},
  {path: 'update', loadChildren: () => import('./page/notice/notice.module').then(m => m.NoticeModule)},
  {path: 'event', loadChildren: () => import('./page/event/event.module').then(m => m.EventModule)},
  {path: 'consulting', loadChildren: () => import('./page/consulting-case/consulting-case.module').then(m => m.ConsultingCaseModule)},
  {path: 'user-modify', loadChildren: () => import('./page/user/user.module').then(m => m.UserModule), canActivate: [MemberGuard]},
  {path: 'my-home', loadChildren: () => import('./page/my-home/my-home.module').then(m => m.MyHomeModule)},
  {path: 'contract', loadChildren: () => import('./page/contract/contract.module').then(m => m.ContractModule)},
  {path: 'apply-firm', loadChildren: () => import('./page/apply-firm/apply-firm.module').then(m => m.ApplyFirmModule)},
  {path: 'notification', loadChildren: () => import('./page/notification/notification.module').then(m => m.NotificationModule)},
  {path: 'report', loadChildren: () => import('./page/report/report.module').then(m => m.ReportModule)},
  {path: 'case-selector', loadChildren: () => import('./page/case-selector/case-selector.module').then(m => m.CaseSelectorModule)},
  {path: 'calculator', loadChildren: () => import('./page/calculator/calculator.module').then(m => m.CalculatorModule)},
  {path: 'cost', loadChildren: () => import('./page/cost/cost.module').then(m => m.CostModule)},
  {path: 'applications', loadChildren: () => import('./page/application/application.module').then(m => m.ApplicationModule), canActivate: [MemberGuard]},
  {path: 'briefcases', loadChildren: () => import('./page/briefcase/briefcase.module').then(m => m.BriefcaseModule)},
  {path: 'invoice', loadChildren: () => import('./page/invoice/invoice.module').then(m => m.InvoiceModule)},
  {path: 'firms', loadChildren: () => import('./page/firm/firm.module').then(m => m.FirmModule)},
  {path: 'firms/manual', loadChildren: () => import('./page/firm/firm-manual/firm-manual.module').then(m => m.FirmManualModule)},
  {path: 'estimates', loadChildren: () => import('./page/estimate/estimate.module').then(m => m.EstimateModule)},
  {path: 'fee-tables', loadChildren: () => import('./page/fee-table/fee-table.module').then(m => m.FeeTableModule)},
  {path: 'auto-estimates', loadChildren: () => import('./page/auto-estimate/auto-estimate.module').then(m => m.AutoEstimateModule)},
  {path: 'card-holder', loadChildren: () => import('./page/card-holder/card-holder.module').then(m => m.CardHolderModule)},
  {path: 'address-book', loadChildren: () => import('./page/address-book/address-book.module').then(m => m.AddressBookModule)},
  {path: 'certification', loadChildren: () => import('./page/certification/certification.module').then(m => m.CertificationModule)},
  {path: 'calendar', loadChildren: () => import('./page/calendar/calendar.module').then(m => m.CalendarModule)},
  {path: 'password/info', loadChildren: () => import('./page/password/change-info/change-info.module').then(m => m.ChangeInfoModule)},
  {path: 'lobby/:lobby', loadChildren: () => import('./page/firm/firm-lobby/firm-lobby.module').then(m => m.FirmLobbyModule)},
  {path: '**', loadChildren: () => import('./page/intro/intro.module').then(module => module.IntroModule)}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router, private spinner: SpinnerService, private bottomToolbarService: BottomToolbarService, private navigator: NavigatorService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.spinner.change(true);
        this.bottomToolbarService.visible.next(true);
      }

      if (event instanceof NavigationEnd) {
        this.navigator.setPreviousState(event.url);
        this.spinner.change(false);
      }
    });
  }
}
