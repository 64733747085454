<mat-drawer-container class="sidenav-container print" fxFlexFill hasBackdrop="true">
  <mat-drawer #leftDrawer [autoFocus]="false" class="sidenav" mode="over" position="start">
    <app-left #leftSide [drawer]="leftDrawer"></app-left>
  </mat-drawer>

  <mat-drawer #rightDrawer [autoFocus]="false" class="sidenav" mode="over" position="end">
    <app-side #rightSide [rightDrawer]="rightDrawer"></app-side>
  </mat-drawer>

  <mat-drawer-content>
    <app-toolbar (toggleRightDrawer)="rightSide.toggle()" class="noprint"></app-toolbar>

    <router-outlet></router-outlet>

    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>

<app-bottom-toolbar (toggle)="leftSide.toggle()"></app-bottom-toolbar>

<app-spinner></app-spinner>
