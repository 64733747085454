<div class="p-v-20 p-h-10" fxLayout="column" ngClass.xs="p-b-50">
  <div class="mat-body">
    <div fxLayoutAlign="start start" fxLayoutGap="10px">
      <mat-form-field fxFlex.gt-xs fxFlex.xs="75">
        <mat-label>전체사건 검색</mat-label>
        <input #keyword (keypress)="search($event, keyword.value)" matInput placeholder="사건명을 입력하세요.">
        <mat-icon (click)="closeSearch(keyword)" *ngIf="keyword.value.length > 0" class="cursor" matSuffix>close
        </mat-icon>
      </mat-form-field>

      <button class="m-t-8 bg-primary" mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="searchedCases.length > 0">
    <div *ngFor="let category of searchedCases">
      <div class="p-5 bg-gray-light mat-caption">{{category.name}}</div>
      <div *ngFor="let lawCase of category.lawCases"
           [queryParams]="{type: 'FAST', caseCode: lawCase.caseCode}" class="p-v-15 p-h-5 list-item"
           routerLink="/estimates/writer/offline">
        <div class="mat-body-1">{{lawCase.name}}</div>
      </div>
    </div>
  </div>

  <div>
    <mat-tab-group (selectedIndexChange)="changeTab($event)" [selectedIndex]="tabIndex" mat-stretch-tabs>
      <mat-tab label="북마크">
        <ng-template matTabContent>
          <div *ngFor="let bookmark of bookmarks" [queryParams]="{type: 'FAST', caseCode: bookmark.code}"
               class="p-v-10 p-h-5 list-item" routerLink="/estimates/writer/offline">
            <div class="mat-body-1 f-600">{{bookmark.name}}</div>
            <div class="mat-caption">{{bookmark.subcategory}}</div>
          </div>
        </ng-template>
      </mat-tab>

      <mat-tab label="최근 사건">
        <ng-template matTabContent>
          <div *ngFor="let category of recentCases">
            <div class="p-5 bg-gray-light mat-caption">{{category.name}}</div>
            <div *ngFor="let lawCase of category.lawCases"
                 [queryParams]="{type: 'FAST', caseCode: lawCase.caseCode}" class="p-v-15 p-h-5 list-item"
                 routerLink="/estimates/writer/offline">
              <div class="mat-body-1 f-600">{{lawCase.name}}</div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

